import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import ListItemText from '@mui/material/ListItemText';
import AutoCompleteComponentBP from './AutoCompleteComponentBP';
import { LoadingWithText } from '../../components'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import {validateDateForCalendarInput, valivDateFormat, isValidDate} from '../../utils/utils'
import { useUserPrefs } from "../../pages/UserPreferences/ProvideUserPrefs";
import { PickersDay } from '@mui/x-date-pickers';


export default function ModalAddDataHighlight({ titleTable, open, onClose, handleSubmit, editData, highlightParameters, loading, sections }) {

  const [state, setState] = useState({});
  const [prevState, setPrevState] = useState({})
  const [table, setTable] = useState(highlightParameters);
  const [errors, setErrors] = useState({})
  const { t } = useTranslation('translation');
  const { prefsData } = useUserPrefs();

  useEffect(() => {
    setTable(highlightParameters)
    if (editData) {
      setState(editData)
      setPrevState(editData)

    } else {
      setState({})
      setPrevState({})
    }

  }, [open, editData])

  let randomId = () => Math.random() * 1000;

  const handleInput = (name, new_value) => {
    let value = new_value

    const { type, optionsParser } = table.find(it => it.field === name);

    if (type === 'date') {
      if(value){
          const formatDate = validateDateForCalendarInput(value, prefsData.date_format)
          if (isValidDate(formatDate, prefsData.date_format)) {
            value = valivDateFormat(value, prefsData.date_format)
            setErrors(prev=>({...prev, [name]: false}))

          }else{
            setErrors(prev=>({...prev, [name]: true}))
          }
          if(value==='9999-12-31'){
            setErrors(prev=>({...prev, [name]: true}))
          }
      }else{
        setErrors(prev=>({...prev, [name]: false}))
      }
    
    }


    if (!value && type === 'number' && value !== 0) {
      setState(prev => ({ ...prev, [name]: null }));
    } else {
      setState(prev => ({ ...prev, [name]: value }));
    }
    // if (optionsParser) {
    //   setState(prev => {
    //     if (prev.BUSINESS_UNIT) {
    //       return {
    //         BUSINESS_UNIT: prev.BUSINESS_UNIT,
    //         REPORT_CODE: value
    //       }
    //     } else {
    //       return { REPORT_CODE: value }
    //     }
    //   })
    //   setSections(highlightParameters[value].sections)
    //   setTable([...data, ...highlightParameters[value].fields])
    // }
  }

  const onHandleSubmit = (e) => {

    e.preventDefault();
    const data = {
      state: {
        BUSINESS_UNIT: state.BUSINESS_UNIT,
        REPORT_CODE: state.REPORT_CODE,
      },
      prevState: {
        BUSINESS_UNIT: prevState.BUSINESS_UNIT,
        REPORT_CODE: prevState.REPORT_CODE,
      },
      dataPut: [],
      dataDelete: [],
      dataPost: [],
    }

    Object.entries(state).filter(it => it[0] != 'BUSINESS_UNIT' && it[0] != 'REPORT_CODE' && it[0] != 'id').map(it => {
      if (prevState[it[0]]) {
        if (it[1] || it[1] === 0) {
          data.dataPut.push(
            {
              BUSINESS_UNIT: state.BUSINESS_UNIT,
              REPORT_CODE: state.REPORT_CODE,
              PARAMETER_NAME: it[0],
              PARAMETER_VALUE: it[1]
            }
          )
        } else if (!it[1] && it[1] !== 0) {
          data.dataDelete.push(
            {
              BUSINESS_UNIT: state.BUSINESS_UNIT,
              REPORT_CODE: state.REPORT_CODE,
              PARAMETER_NAME: it[0],
              PARAMETER_VALUE: it[1]
            }
          )
        }
      } else if (it[1] || it[1] === 0) {
        data.dataPost.push(
          {
            BUSINESS_UNIT: state.BUSINESS_UNIT,
            REPORT_CODE: state.REPORT_CODE,
            PARAMETER_NAME: it[0],
            PARAMETER_VALUE: it[1]
          }
        )
      }
    })
    if (editData) {
      handleSubmit({ ...data, id: editData.id })
    } else {
      handleSubmit({ ...data, isNew: true, id: randomId().toString() })
    }
  }


  const createField = (it, id) => {

    const { type, headerName, field, valueOptions, required, valueFormatted, valueParser, min, multiple, components } = it
    switch (type) {
      case 'singleSelect':
        return (
          <div className='bpx-modal-add-data' key={id + 'div'}>
            <FormControl size='small'>
              <InputLabel key={id + 'label'} id="demo-simple-select-label">{headerName}</InputLabel>
              <Select
                size='small'
                label={headerName}
                labelId="demo-simple-select-label"
                value={state[field] || ''}
                required={!!required}
                onChange={(e) => handleInput(field, e.target.value)}
                key={id + 'select'}
                className='bpx-modal-highlight-field'
              >
                {valueOptions ?
                  valueOptions.map((item, i) => {
                    if (typeof item === 'object') {
                      return <MenuItem key={i} value={item.value}>{item.option}</MenuItem>
                    }
                    return <MenuItem key={i} value={item}>{item}</MenuItem>
                  })
                  : null}
              </Select>
            </FormControl>
          </div>
        )
      case 'multiSelect':
        return (
          <div className='bpx-modal-add-data' key={id + 'div'}>
            <FormControl size='small'>
              <InputLabel key={id + 'label'} id="demo-simple-select-label">{headerName}</InputLabel>
              <Select
                size='small'
                label={headerName}
                labelId="demo-simple-select-label"
                value={state[field] ? valueFormatted ? valueFormatted(state[field]) : state[field] : []}
                required={!!required}
                renderValue={(selected) => selected.join(',')}
                onChange={(e) => handleInput(field, valueParser ? valueParser(e.target.value) : e.target.value)}
                key={id + 'select'}
                className='bpx-modal-highlight-field'
                multiple
              >
                {valueOptions ?
                  valueOptions.map((item, i) => {
                    if (typeof item === 'object') {
                      const check = state[field] ?
                        valueFormatted ?
                          !!valueFormatted(state[field]).filter(el => el === item.value).length :
                          state[field].includes(item.value) :
                        false;
                      return (<MenuItem key={i} value={item.value}>
                        <Checkbox checked={check} />
                        <ListItemText primary={item.option} />
                      </MenuItem>)
                    }

                    return (<MenuItem key={i} value={item}>
                      <Checkbox checked={state[field] ? state[field].includes(item) : false} />
                      <ListItemText primary={item} />
                    </MenuItem>)
                  })
                  : null}
              </Select>
            </FormControl>
          </div>
        )
      case 'date':
        return (
          <div className='bpx-modal-add-data' key={id + 'div'}>
            <LocalizationProvider dateAdapter={AdapterDateFns} key={id + 'LocalizationProvider'}>
              <DesktopDatePicker
                key={id + 'DesktopDatePicker'}
                label={headerName}
                className='bpx-modal-highlight-field'
                inputFormat={prefsData.date_format}
                onChange={(e) => handleInput(field, e)}
                value={state[field] || null}
                renderInput={(params) =>
                  <TextField
                    {...params} 
                    size='small'
                    inputProps={
                      {
                        ...params.inputProps,
                        placeholder: prefsData.date_format,
                      }
                    }
                    error={errors[field]}
                  />
                }
              />
            </LocalizationProvider>
          </div>
        )
      case 'boolean':
        return (
          <div className='bpx-modal-add-data' key={id + 'div'}>
            <FormControlLabel
              control={<Checkbox
                checked={state[field] ? valueFormatted ? valueFormatted(state[field]) : state[field] : false}
                onChange={(e) => handleInput(field, valueParser ? valueParser(e.target.checked) : e.target.checked)}
              />}
              label={headerName} />
          </div>
        )
      case 'number':
        return (
          <div className='bpx-modal-add-data' key={id + 'div'}>
            <TextField
              type='number'
              inputProps={{ inputMode: 'numeric', min: min || 0 }}
              key={id}
              value={(state[field] || state[field] === 0) ? state[field] : ''}
              label={headerName}
              className='bpx-modal-highlight-field'
              name={headerName}
              required={!!required}
              onChange={(e) => handleInput(field, e.target.value)}
            />
          </div>
        )
      case 'autoComplete':
        return (
          <div className='bpx-modal-add-data bpx-modal-add-data-autocomplete' key={id + 'div'}>
            <div >
              <FormControl>
                <AutoCompleteComponentBP
                  key={id}
                  data={it}
                  prevState={state[field]}
                  onChangeHandler={(value) => handleInput(field, value)}
                  multiple={multiple}
                />
              </FormControl>
            </div>
          </div>
        )
      case 'empty':
        return null;
      default:
        return (
          <div className='bpx-modal-add-data' key={id + 'div'}>
            <FormControl size="small">
              <TextField
                key={id}
                size='small'
                value={state[field] || ''}
                label={headerName}
                className='bpx-modal-highlight-field'
                name={headerName}
                required={!!required}
                onChange={(e) => handleInput(field, e.target.value)}
              />
            </FormControl>
          </div>
        )
    }
  }

  const rows = sections.map((it, id) => {

    return (
      <Box key={id} >
        <p style={{ margin: 0 }}>
          {it.field}
        </p>
        <hr />
        <Box className="bpx-modal-highlight">
          {it.children.map((child, idChild) => {
            const tableElement = table.find(tableEl => tableEl.field == child);
            return createField(tableElement, idChild)
          })}
        </Box>
      </Box>
    )
  });

  const displayBlockForm = () => {
    return (
      <>
        <DialogTitle sx={{ paddingBottom: 0 }}>
          {titleTable}
          <hr />
          <div className="close-for-modal-window">
            <IconButton onClick={onClose} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>


        <form onSubmit={onHandleSubmit}>
          <DialogContent>
            {rows}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              {t("MODAL.BUTTONS.CANCEL")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!!Object.values(errors).filter(it=>it===true).length}
            >
              {t("MODAL.BUTTONS.SAVE")}
            </Button>
          </DialogActions>

        </form>
      </>
    )
  }

  const displayLoading = (
    loading
      ? <LoadingWithText text="Your request is being processed, please wait." size={50} />
      : displayBlockForm()
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { maxWidth: '725px' } }}
    >

      {displayLoading}

    </Dialog>
  )
}