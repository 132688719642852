import React, { useState, useEffect, useCallback } from 'react';
import { 
  DataGridPro,
  useGridApiRef,
 } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next'
import { CustomToolbar } from '../../components';

import { 
  getTreeDataPath, customer_hierarchy_data_fetcher, 
  GroupingCellWithLazyLoading
} from './utils'
import { dateTimeFormatter, checkInFilterCache, applyFilters, checkSize } from '../../utils/utils'

import { useFetch } from '../../hooks'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs";



import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'

export default function CustomerHierarchy() {

  const { t } = useTranslation('translation')
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const value_bu = localStorage.getItem('bu_bpx')
  const [rows, setRows] = useState([]);
  const apiRef = useGridApiRef();
  const request = useFetch()
  const [grid_loading, setGridLoading] = useState(false);
  const [expanded, setExpanded] = useState('panel1')
  const { prefsData, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs, saveUserPrefs } = useUserPrefs();
  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order && prefsData.reports_column_order.Customer_Hier || []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Customer_Hier || 'm');
  // const todayDate = new Date().toISOString().split('T')[0];
  // const [filter, setFilter] = useState({
  //   dateFrom: todayDate, //todayDate or new Date() or null
  // });

  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  
  const [filter, setFilter] = useState([
    {
      firstKeyOption: "date",
      title: t('CustHier.DATE_FILTER'), 
      valueFilter: new Date().toISOString().split('T')[0],
      formatField: "date"
    }
  ])

  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }


  const cachedfilters = localStorage.getItem("filters")
  var filterObj = {
      customerHierList: []
  }

  if (cachedfilters && Object.keys(cachedfilters).length) { 
      filterObj = JSON.parse(cachedfilters)
      if (!('customerHierList' in filterObj)) {
          filterObj["customerHierList"] = []
      }
  }

  const columns = React.useMemo(() => {
    const allColumns = {
      NAME1: { 
          field: 'NAME1', 
          headerName: t('CustHier.NAME1'), 
          flex: 0.11,
          minWidth: 50, 
          hideable: false 
      },
      NAME2: { 
          field: 'NAME2', 
          headerName: t('CustHier.NAME2'), 
          flex: 0.11,
          minWidth: 50, 
          hideable: false 
      },
      DATE_FROM: { 
          field: 'DATE_FROM', 
          headerName: t('CustHier.DATE_FROM'), 
          flex: 0.11,
          minWidth: 50, 
          hideable: false,
          renderCell: ({ value }) => {
              if (isNaN(value)) { 
                return <div className="MuiDataGrid-cellContent">{value}</div>;
              }
              return <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
          },
      },
      DATE_TO: { 
          field: 'DATE_TO', 
          headerName: t('CustHier.DATE_TO'), 
          flex: 0.11,
          minWidth: 50, 
          hideable: false,
          renderCell: ({ value }) => {
              if (isNaN(value)) {
                return <div className="MuiDataGrid-cellContent">{value}</div>;
              }
              return <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
          },
      },
      CITY: { 
          field: 'CITY', 
          headerName: t('CustHier.CITY'), 
          flex: 0.11,
          minWidth: 50,  
          hideable: false 
      },
      STREET_HOUSE_NUM: { 
          field: 'STREET_HOUSE_NUM', 
          headerName: t('CustHier.STREET_HOUSE_NUM'), 
          flex: 0.11,
          minWidth: 50, 
          hideable: false 
      },
      POSTAL_CODE: { 
          field: 'POSTAL_CODE', 
          headerName: t('CustHier.POSTAL_CODE'), 
          flex: 0.11,
          minWidth: 50, 
          hideable: false 
      },
      BUSINESS_AREA: { 
          field: 'BUSINESS_AREA', 
          headerName: t('CustHier.BUSINESS_AREA'), 
          flex: 0.11,
          minWidth: 50, 
          hideable: false 
      }
    }

    return orderedColumns.length?orderedColumns.reduce((acc, field) => {
      return [...acc, allColumns[field]];
    }, []):Object.values(allColumns);
  }, [orderedColumns]);

  useEffect(() => {
    if (value_bu) {
      setGridLoading(true)
      setRows([]);
    }
  }, [value_bu]);

  // useEffect(() => {
  //     return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
  //         silentUpdateUserPrefs()
  //     })
  // }, [apiRef])
  
  useEffect(() => {

    if (!value_bu) {
      return undefined;
    }
    // if (filter.dateFrom === null) {
    //   return undefined;
    // }

    customer_hierarchy_data_fetcher(value_bu, [], tokenBpxRequest, filter, request)
    .then(data => {
      setRows(data)
      setGridLoading(false)
    })
  
    const handleRowExpansionChange = async (node) => {

      if (filter.dateFrom === null) {
        return undefined;
      }
  
      const row = apiRef.current.getRow(node.id);
  
      if (!node.childrenExpanded || !row || row.childrenFetched) {
        return;
      }
 
      apiRef.current.updateRows([
        {
          id: `placeholder-children-${node.id}`,
          node_label: t('CustHier.PLEASE_WAIT'),
          hierarchy: [...row.hierarchy, ''],
        },
      ]);

      const childrenRows = await customer_hierarchy_data_fetcher(value_bu, row.hierarchy, tokenBpxRequest, filter, request)

      apiRef.current.updateRows([
        ...childrenRows,
        { id: node.id, childrenFetched: true },
        { id: `placeholder-children-${node.id}`, _action: 'delete' },
      ]);
  
      if (childrenRows.length) {
        apiRef.current.setRowChildrenExpansion(node.id, true);
      }
    };
  
      /**
       * By default, the grid does not toggle the expansion of rows with 0 children
       * We need to override the `cellKeyDown` event listener to force the expansion if there are children on the server
       */
       const handleCellKeyDown = (params, event) => {
        const cellParams = apiRef.current.getCellParams(params.id, params.field);
        if (cellParams.colDef.type === 'treeDataGroup' && event.key === ' ') {
          event.stopPropagation();
          event.preventDefault();
          event.defaultMuiPrevented = true;
  
          apiRef.current.setRowChildrenExpansion(
            params.id,
            !params.rowNode.childrenExpanded,
          );
        }
      };
  
      apiRef.current.subscribeEvent('rowExpansionChange', handleRowExpansionChange);
      apiRef.current.subscribeEvent('cellKeyDown', handleCellKeyDown, {
        isFirst: true,
      });
  
  }, [tokenBpxRequest, apiRef, value_bu, filter]);

  const applyFilter = useCallback((item) => {

    if (item.value) {

        checkInFilterCache(item, filterObj.customerHierList)
        localStorage.setItem("filters", JSON.stringify(filterObj))
  
    }

    apiRef.current.upsertFilterItem(item)
  }, [apiRef])


  const deleteFilter = useCallback((item) => {

      filterObj.customerHierList = filterObj.customerHierList.filter(fl => fl.id !== item.id)
      localStorage.setItem("filters", JSON.stringify(filterObj))

      apiRef.current.deleteFilterItem(item)
    
  }, [apiRef])




  const CUSTOM_GROUPING_COL_DEF = {
    headerName: t("CustHier.GROUP"),
    renderCell: (params) => <GroupingCellWithLazyLoading {...params} />
  };

  const objBreadcrumb = [
    { label: t('Breadcrumb.home'), link: '/' },
    { label: t('Breadcrumb.masterData'), link: '' },
    { label: t('Breadcrumb.customerHierarchy'), link: '/master-data/customer_hierarchy' },
  ]

  const handleColumnOrderChange = React.useCallback((params) => {
    setOrderedColumns((prevOrderedColumns) => {
      const newOrderedColumns = [...prevOrderedColumns];
      const oldIndex = params.oldIndex;
      const targetIndex = params.targetIndex;
      const oldColumn = prevOrderedColumns[oldIndex];
      newOrderedColumns.splice(oldIndex, 1);
      newOrderedColumns.splice(targetIndex, 0, oldColumn);
      saveUserPrefsWithoutRefresh({
        ...prefsData,
        reports_column_order: {
          ...prefsData.reports_column_order,
          Customer_Hier: newOrderedColumns
        }
      })
      return newOrderedColumns;
    });
  }, []);

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Customer_Hier: value
      }
    })
  }
 

  return (
    <div className='tableContainer darkmode-for-svg'>
      <DataGridPro
        treeData
        apiRef={apiRef}
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }
        }} 
        rows={rows}
        columns={columns}
        // onColumnOrderChange={handleColumnOrderChange}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={CUSTOM_GROUPING_COL_DEF}
        disableChildrenFiltering
        hideFooterRowCount={true}
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        initialState={{
          filter: {
              filterModel: {
                  items: filterObj && filterObj.customerHierList,
              },
          }
        }}
        localeText={{
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.customerHierarchy"),
            turn: false,
            filters: 
              <CustomMultipleFilter 
                onFilter={changeFilterValues} 
                dataFilter={filter} 
                loading={grid_loading}
              />,
              isFontSize: true,
              fontSize: fontSize,
              onChangeFontSize: onChangeFontSize,
          },
          filterPanel: {
            filterFormProps: {
              applyFilterChanges: applyFilter,
              deleteFilter: deleteFilter,
            }
          } 
        }}
        loading={grid_loading}
        onRowsScrollEnd={handleOnRowsScrollEnd}
      />
    </div>
    
  );
}
