import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import ListItemText from '@mui/material/ListItemText';
import AutoCompleteComponentBP from './AutoCompleteComponentBP';
import { LoadingWithText } from '../../components'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import moment from 'moment'
import { Cron } from 'react-js-cron'
import FormHelperText from '@mui/material/FormHelperText';
import AutoCompleteCustom from './AutoCompleteCustom'
import 'react-js-cron/dist/styles.css'
import {validateDateForCalendarInput, valivDateFormat, isValidDate} from '../../utils/utils'
import { useUserPrefs } from "../../pages/UserPreferences/ProvideUserPrefs";
import { PickersDay } from '@mui/x-date-pickers';


export default function ModalAddData({ data, titleTable, open, onClose, handleSubmit, editData, loading, defaultClass }) {
  const [state, setState] = useState({});
  const [disabledBtn, setDsabledBtn] = useState(false);
  const [table, setTable] = useState(data);
  const [errors, setErrors] = useState({})

  const { t } = useTranslation('translation');
  const { prefsData } = useUserPrefs();
  
  useEffect(() => {
    setTable(data);
  }, [data])


  useEffect(() => {
    if (editData) {
      const optionsWithParser = table.filter(it => it.optionsParser);
      const dependedItem = table.filter(it=>it.depended&&editData[it.field]&&!editData[it.depended]);
        if(dependedItem.length){
          setDsabledBtn(true)
        }else{
          setDsabledBtn(false)
        }
      if (optionsWithParser.length) {
        optionsWithParser.map(it => {
          const { field, valueRow } = it.optionsParser(editData[it.field]);
          setTable((prev) => prev.map(item => {
            if (item.field === field) {
              const { valueOptions } = data.find(el => el.field === field);
              if (valueOptions && valueOptions.length) {
                return { ...valueRow, valueOptions }
              }
              return valueRow;
            }
            return item;
          }))
        })
      }

      setState(editData)
    } else {

      table.map(it => {
        const { defaultValue, isDefaultValue, defaultType } = it;
        let state = null;
        if (isDefaultValue) {
          state = defaultValue
        }
        setState((prevState) => ({ ...prevState, [it.field]: state }));
        if (defaultType) {
          it.type = defaultType
        }
        return it
      })
      setTable(table)
    }
  }, [open])

  let randomId = () => Math.random() * 100000;

  const handleInput = (name, new_value) => {
    let value = new_value
    let disabled = false
    const { type, required, optionsParser, depended } = table.find(it => it.field === name);
    if (optionsParser) {
      const { field, valueRow } = optionsParser(value);

      setTable((prev) => prev.map(item => {
        if (item.field === field) {
          const { valueOptions } = data.find(el => el.field === field);
          if (valueOptions && valueOptions.length) {

            return { ...valueRow, valueOptions }
          }
          return valueRow;
        }
        return item;
      }))
      // setDsabledBtn(false)
      setState(prev => ({ ...prev, [field]: valueRow.defaultValue || null }));
    }
    if (type === 'date') {
      const formatDate = validateDateForCalendarInput(value, prefsData.date_format)
      if (isValidDate(formatDate, prefsData.date_format)) {
        value = valivDateFormat(value, prefsData.date_format)
        setErrors(prev=>({...prev, [name]: false}))

      }else{
        setErrors(prev=>({...prev, [name]: true}))
        disabled = true
      }
      if(value==='9999-12-31'){
        disabled = true
        setErrors(prev=>({...prev, [name]: true}))
      }
      if (!value) {
        if(required){
          disabled = true
          setErrors(prev=>({...prev, [name]: true}))
        }else{
          setErrors(prev=>({...prev, [name]: false}))
        }
       
      } 
    }
    const dependedItem = table.filter(it=>it.depended&&state[it.field]&&!state[it.depended]);
    if(depended&&((value&&!state[depended])||(!value&&state[depended]))){
      disabled = true
      
    } else {
      const dependedItem = table.filter(it=>it.depended&&state[it.field]&&!state[it.depended]);
      if(dependedItem.length&&!dependedItem.find(it=>it.depended==name||it.field==name)&&
          dependedItem.find(it=>(state[it.depended]&&!state[it.field])||!state[it.depended]&&state[it.field])
        ){
          disabled = true
        }
    }
    setDsabledBtn(disabled)
    if (!value && type === 'number' && value !== 0) {
      setState(prev => ({ ...prev, [name]: null }));
    } else {
      setState(prev => ({ ...prev, [name]: value }));
    }
  }
  const onHandleSubmit = (e) => {
    e.preventDefault();
    if (editData) {
      handleSubmit({...state, isNew: false})
    } else {
      handleSubmit({ ...state, isNew: true, id: randomId().toString() })
    }
  }

  const handelCronError = (e) => {
    if (e) {
      setDsabledBtn(true)
    } else {
      setDsabledBtn(false)
    }
  }


  const createField = (it, id, onHandleInput = handleInput, stateData = state) => {
    const { type, headerName, field, valueOptions, required, valueFormatted, valueParser, min, multiple, components, description, style, step, isAllOption, depended } = it
    const addStyle = style?style:{};
    switch (type) {
      case 'singleSelect':
        return (
          <div className='bpx-modal-add-data' key={id + 'div'} style={addStyle}>
            <FormControl size="small">
              <InputLabel key={id + 'label'} id="demo-simple-select-label">{headerName}</InputLabel>
              <Select
                label={headerName}
                labelId="demo-simple-select-label"
                value={stateData[field] || ''}
                required={!!required}
                onChange={(e) => onHandleInput(field,valueParser?valueParser(e.target.value):e.target.value)}
                key={id + 'select'}
                className='bpx-modal-add-data-field'
              >
                {valueOptions ?
                  valueOptions.map((item, i) => {
                    if (typeof item === 'object') {
                      return <MenuItem key={i} value={item.value}>{item.option}</MenuItem>
                    }
                    return <MenuItem key={i} value={item}>{item}</MenuItem>
                  })
                  : null}
              </Select>
              {
                description &&
                <FormHelperText id="accountId-error" sx={{ width: '250px', margin: 0, wordBreak: 'break-word' }}>
                  {description}
                </FormHelperText>
              }
            </FormControl>
          </div>
        )
      case 'multiSelect':
        return (
          <div className='bpx-modal-add-data' key={id + 'div'}>
            <FormControl size="small">
              <InputLabel key={id + 'label'} id="demo-simple-select-label">{headerName}</InputLabel>
              <Select
                label={headerName}
                labelId="demo-simple-select-label"
                value={stateData[field] ? valueFormatted ? valueFormatted(stateData[field]) : stateData[field] : []}
                required={!!required}
                renderValue={(selected) => selected.join(',')}
                onChange={(e) => onHandleInput(field, valueParser ? valueParser(e.target.value) : e.target.value)}
                key={id + 'select'}
                className='bpx-modal-add-data-field'
                multiple
              >
                {valueOptions ?
                  valueOptions.map((item, i) => {
                    if (typeof item === 'object') {
                      return <MenuItem key={i} value={item.value}>{item.option}</MenuItem>
                    }

                    return (<MenuItem key={i} value={item}>
                      <Checkbox checked={stateData[field] ? stateData[field].includes(item) : false} />
                      <ListItemText primary={item} />
                    </MenuItem>)
                  })
                  : null}
              </Select>
              {
                description &&
                <FormHelperText id="accountId-error" sx={{ width: '250px', margin: 0,  wordBreak: 'break-word' }}>
                  {description}
                </FormHelperText>
              }
            </FormControl>
          </div>
        )
      case 'date':
        return (
          <div className='bpx-modal-add-data' key={id + 'div'}>
            <FormControl size='small'>
            <LocalizationProvider dateAdapter={AdapterDateFns} key={id + 'LocalizationProvider'}>
              <DesktopDatePicker
                key={id + 'DesktopDatePicker'}
                label={headerName}
                className='bpx-modal-add-data-field'
                inputFormat={prefsData.date_format}
                maxDate='9999-12-30'
                onChange={(e) => onHandleInput(field, e)}
                value={stateData[field] ? valueFormatted ? valueFormatted(stateData[field]) : stateData[field] : null}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    size="small"
                    inputProps={
                      {
                        ...params.inputProps,
                        placeholder: prefsData.date_format
                      }
                    }
                    error={(required && !stateData[field])||stateData[field]==='9999-12-31'||errors[field]}
                  />
                }
              />
               {
                description &&
                <FormHelperText id="accountId-error" sx={{ width: '250px', margin: 0,  wordBreak: 'break-word' }}>
                  {description}
                </FormHelperText>
              }
            </LocalizationProvider>
            </FormControl>
          </div>
        )
      case 'date-and-time':
        return (
          <div className='bpx-modal-add-data' key={id + 'div'} style={addStyle}>
            <FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns} key={id + 'LocalizationProvider'}>
              <DateTimePicker
                key={id + 'DateTimePicker'}
                label={headerName}
                className='bpx-modal-add-data-field'
                inputFormat={"yyyy-MM-dd " + it.format}
                ampm={it.format.includes('hh')}
                referenceDate={moment('yyyy-MM-ddThh:mm:ss')}
                onChange={(e) => onHandleInput(field, valueParser(e))}
                value={stateData[field] ? valueFormatted ? valueFormatted(stateData[field]) : stateData[field] : null}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    size="small"
                    inputProps={
                      {
                        ...params.inputProps,
                        placeholder: 'YYYY-MM-DD ' + it.format
                      }
                    }
                    error={required && !stateData[field]}
                  />
                }
              />
              {
                description &&
                <FormHelperText id="accountId-error" sx={{ width: '250px', margin: 0,  wordBreak: 'break-word' }}>
                  {description}
                </FormHelperText>
              }
            </LocalizationProvider>
            </FormControl>
          </div>
        )
      case 'boolean':
        return (
          <div className='bpx-modal-add-data bpx-modal-add-data-field' key={id + 'div'} style={addStyle}>
            <FormControl size='small'>
            <FormControlLabel
              control={<Checkbox
                checked={stateData[field] ? valueFormatted ? valueFormatted(stateData[field]) : stateData[field] : false}
                onChange={(e) => onHandleInput(field, valueParser ? valueParser(e.target.checked) : e.target.checked)}
              />}
              label={headerName} />
               {
                description &&
                <FormHelperText id="accountId-error" sx={{ width: '250px', margin: 0,  wordBreak: 'break-word' }}>
                  {description}
                </FormHelperText>
              }
              </FormControl>
          </div>
        )
      case 'number':
        return (
          <div className='bpx-modal-add-data' key={id + 'div'}>
            <FormControl size="small">
              <TextField
                type='number'
                inputProps={{ 
                  inputMode: 'numeric', 
                  min: min || 0, 
                  step: step || 1,             
                }}
                key={id}
                value={valueFormatted&&(stateData[field] || stateData[field] === 0)? valueFormatted((stateData[field])):(stateData[field] || stateData[field] === 0) ? stateData[field] : ''}
                label={headerName}
                className='bpx-modal-add-data-field'
                name={headerName}
                size="small"
                required={!!required}
                onChange={(e) => onHandleInput(field,  valueParser ? valueParser(e.target.value) : e.target.value)}
              />
              {
                  description &&
                  <FormHelperText id="accountId-error" sx={{ width: '250px', margin: 0,  wordBreak: 'break-word' }}>
                    {description}
                  </FormHelperText>
                }
            </FormControl>
            
          </div>
        )
      case 'autoComplete':
        return (
          <div className='bpx-modal-add-data bpx-modal-add-data-autocomplete' key={id + 'div'}>
            <div >
              <FormControl size="small">
                <AutoCompleteComponentBP
                  key={id}
                  data={it}
                  prevState={stateData[field]}
                  onChangeHandler={(value) => onHandleInput(field, value)}
                  multiple={multiple}
                  isAllOption={isAllOption}
                />
                 {
                description &&
                <FormHelperText id="accountId-error" sx={{ width: '250px', margin: 0,  wordBreak: 'break-word' }}>
                  {description}
                </FormHelperText>
              }
              </FormControl>
            </div>
          </div>
        )
        case 'autoCompleteCustom':
          return (
            <div className='bpx-modal-add-data bpx-modal-add-data-autocomplete' key={id + 'div'}>
              <div >
                <FormControl size="small">
                  <AutoCompleteCustom
                   key={id}
                   data={it}
                   onChangeHandler={(value) => onHandleInput(field, value)}
                  />
                   {
                  description &&
                  <FormHelperText id="accountId-error" sx={{ width: 'auto', margin: 0,  wordBreak: 'break-word' }}>
                    {description}
                  </FormHelperText>
                }
                </FormControl>
              </div>
            </div>
          )
      case 'array':
        return components.map((component, id) => {
          const ind = table.length + id
          const { valueOptions } = data.find(el => el.field === component.field) || [];

          if (valueOptions && valueOptions.length) {

            return createField(
              { ...component, valueOptions },
              ind,
              (name, value) => onHandleInput(name, valueFormatted(stateData[field], value, id)),
              { [component.field]: valueParser(stateData[field], id) }
            )
          }
          return createField(component,
            ind,
            (name, value) => onHandleInput(name, valueFormatted(stateData[field], value, component.name || '')),
            { [component.field]: valueParser(stateData[field], component.name || '') }
          )
        })
      case 'empty': case 'SEQUENCE':
        if (valueParser) {
          valueParser()
        }
        return null;
      case 'crontab_expr':
        return <div className='bpx-modal-add-data' key={id + 'div'} style={{ marginRight: '100%',height:'auto' }}>
          <div>
          <Cron 
          value={stateData[field] || ''} 
          setValue={(e) => {
            onHandleInput(field, e)
          }} 
          onError={handelCronError}/>
           <FormControl size="small">
              <TextField
                size="small"
                key={id}
                value={stateData[field] || ''}
                label={headerName}
                className='bpx-modal-add-data-field'
                name={headerName}
                required={!!required}
                onChange={(e) => onHandleInput(field, e.target.value)}
              />
              {
                description &&
                <FormHelperText id="accountId-error" sx={{ width: '250px', margin: 0,  wordBreak: 'break-word' }}>
                  {description}
                </FormHelperText>
              }
            </FormControl>
            {/* <Scheduler
            key={id}
            cron={stateData[field] || ''}
            setCron={(e) => {
              onHandleInput(field, e)
            }}
            setCronError={handelCronError}
            isAdmin={true}
          /> */}
          </div>
          
        </div>
      default:
        return (
          <div className='bpx-modal-add-data' key={id + 'div'}>
            <FormControl size="small">
              <TextField
                size="small"
                key={id}
                value={valueFormatted ? valueFormatted(stateData[field] || '') : stateData[field] || ''}
                label={headerName}
                className='bpx-modal-add-data-field'
                name={headerName}
                required={!!required}
                onChange={(e) => onHandleInput(field, valueParser ? valueParser(e.target.value) : e.target.value)}
                error={Boolean(depended&&((stateData[field]&&!state[depended])||(!stateData[field]&&state[depended])))}
              />
              {
                description &&
                <FormHelperText id="accountId-error" sx={{ width: '250px', margin: 0,  wordBreak: 'break-word' }}>
                  {description}
                </FormHelperText>
              }
            </FormControl>
          </div>
        )
    }
  }

  const rows = table.map((it, id) => {
    return createField(it, id)
  });

  const displayBlockForm = () => {
    return (
      <>
        <DialogTitle sx={{ paddingBottom: 0, textAlign: "center" }}>
          {titleTable} | {editData ? t("MODAL.CHANGE_DATA") : t("MODAL.ADD_DATA")}
          <hr />
          <div className="close-for-modal-window">
            <IconButton onClick={onClose} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form onSubmit={onHandleSubmit}>

          <DialogContent sx={{ paddingTop: 0 }} >
            <Box className='bpx-modal-add-data-box'>
              {rows}
            </Box>

          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              {t("MODAL.BUTTONS.CANCEL")}
            </Button>
            <Button
              disabled={disabledBtn||!!Object.values(errors).filter(it=>it===true).length}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t("MODAL.BUTTONS.SAVE")}
            </Button>
          </DialogActions>
        </form>
      </>
    )
  }

  const displayLoading = (
    loading
      ? <LoadingWithText text="Your request is being processed, please wait." size={50} />
      : displayBlockForm()
  )

  return (
    <Dialog
      className={defaultClass?defaultClass:''}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { maxWidth: '858px' } }}
    >
      {displayLoading}
    </Dialog>
  )
}
