import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { useFetch } from "../../hooks"
import { backend_url } from "../../settings"
import { getParam } from "../../utils/utils"
import { LoadingWithText } from '../../components'

export const AdjustmentActionModal = ({ open, onCloseHandle, handleSubmit, data }) => {

  const { type, addInformation } = data

  const { t } = useTranslation('translation')
  const request = useFetch()
  const value_bu = localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem("token_bpx")
  const [loading, setLoading] = useState(true)
  const [emails, setEmails] = useState([])
  const templateDate = {
    resend: {
      headerName: t("Adjustments.MODAL.APPROVAL_WORKFLOW"),
      msg1: t("Adjustments.MODAL.resend_msg"),
      msg2: '',
    },
    restart: {
      headerName: t("Adjustments.MODAL.APPROVAL_WORKFLOW"),
      msg1: t("Adjustments.MODAL.restart_msg1"),
      msg2: t("Adjustments.MODAL.restart_msg2"),
    },
    reject: {
      headerName: t("Adjustments.MODAL.APPROVAL_WORKFLOW"),
      msg1: t("Adjustments.MODAL.reject_msg1"),
      msg2: t("Adjustments.MODAL.reject_msg2"),
    }
  }

  useEffect(() => {
    if (open) {
      if (type === 'resend') {
        const queryParams = {
          BUSINESS_UNIT: value_bu,
          LEVELS: [addInformation.CURRENT_LEVEL],
          FUNCTION: 'Accrual Adjustment'
        }
        const queryString = getParam(queryParams).toString()
        request.fetchData(
          `${backend_url.config_approval_workflow}?${queryString}`,
          "GET",
          null,
          false,
          tokenBpxRequest,
          false
        ).then((data) => {
          setEmails(data.map(it => {
            const approvers = []
            if (it.MAIN_APPROVERS) {
              approvers.push(it.MAIN_APPROVERS)
            }
            if (it.BACKUP_APPROVERS) {
              approvers.push(it.BACKUP_APPROVERS)
            }
            return approvers
          }).flat())
          setLoading(false)
        })

      } else {
        setLoading(false)
      }
    } else {
      setLoading(true)
    }
  }, [open])

  const onHandleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    handleSubmit(type, addInformation.DOCUMENT_NUMBER, addInformation.AC_AD_ID);
  }

  const displayBlockForm = () => {


    return (
      <div className='bpx-block-dialog-wrap bpx-block-recalculate'>
        <DialogTitle className='bpx-header-regenerate' sx={{ paddingBottom: 0 }}>
          <div className='bpx-title-regenerate'>
            {templateDate[type].headerName} {addInformation.AC_AD_ID}
          </div>
          <div className="close-for-modal-window">
            <IconButton onClick={onCloseHandle} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form onSubmit={onHandleSubmit}>
          <DialogContent className='bpx-block-dialog-content'>

            <div className='bpx-block-dialog-top' style={{ textAlign: 'center' }}>
              <p>{templateDate[type].msg1} {type === 'resend' ? emails.join(', ') : addInformation.AC_AD_ID}?</p>
              <p>{templateDate[type].msg2}</p>

            </div>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              // color="primary"
              onClick={onCloseHandle}
            >
              {t("MODAL.BUTTONS.NO")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {t("MODAL.BUTTONS.YES")}
            </Button>
          </DialogActions>
        </form>
      </div>
    )

  }
  const displayLoading = (
    loading
      ? <LoadingWithText text="Your request is being processed, please wait." size={50} />
      : displayBlockForm()
  )

  return (
    <Dialog
      open={open}
      onClose={onCloseHandle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { minWidth: '800px' } }}
    >
      {displayLoading}
    </Dialog>
  )

}

